import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">
                  ALL PIZ BUIN<sup>®</sup> PRODUCTS
                </h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filter by: All</option>
                          <option value="1,2,6,9,12,13">Sensitive skin</option>
                          <option value="12,18,19">After sun</option>
                          <option value="1,2,4">Face protection</option>
                          <option value="1">Fast-absorbing</option>
                          <option value="13">Waterproof</option>
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <a
                    href="/uk/our-products/moisturising/#sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/moisturising/#sun-lipstick"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>                 
                  <a
                    href="/uk/our-products/tan_protect/#tan-accelerating-oil-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/597e55fa85eadc497dda9761c9c29e48_f305.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN ACCELERATING OIL SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/tan_protect/#tan-intensifying-sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/PB_TanProtect_Tube_15ml_SPF15.jpg" alt="TAN INTENSIFYING SUN LOTION" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/tan_protect/#tan-intensifying-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/33e7e9d0e3e36224abe8382693902bac_f547.png" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/allergy/#sun-sensitive-skin-lotion"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-lotion-50spf-200ml-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN SENSITIVE SKIN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>                  
                  <a
                    href="/uk/our-products/allergy/#sun-sensitive-skin-face-cream"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-face-cream-50spf-50ml-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN SENSITIVE SKIN FACE CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/mountain/#mountain-suncream"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/ab1e2f557de9bb14e5f1e24447874e3e_f345.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOUNTAIN SUNCREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/mountain/#mountain-suncream-lipstick"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/d7c5f1cffc265a491e721285affdcad3_f24.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOUNTAIN SUNCREAM + LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>                  
                  <a
                    href="/uk/our-products/aftersun/#soothing-cooling-moisturising-lotion"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-200ml-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SOOTHING &amp; COOLING MOISTURISING LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/uk/our-products/aftersun/#tan-intensifying-moisturising-lotion"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml-gal.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING MOISTURISING LOTION
                        </h3>
                      </div>
                    </div>
                  </a>                 
                  <a
                    href="/uk/our-products/aftersun/#instant-mist-spray"
                    className="gallery-page__list__item"
                    data-pid="8"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/3574661407685_T1.jpg" alt="ALL PIZ BUIN® PRODUCTS" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After sun{" "}
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          AFTER SUN INSTANT RELIEF MIST SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('.products-page__product__details__benefits__more').on('click', function () {\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif (box.is(':visible')) {\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
